import Cookies from 'js-cookie'
import type {ModalOption} from "bootstrap";


type CookieConsent = "allowed" | 'denied' | 'not_set';

const cookieConsentCookieName = 'allowCookies';

function maybeInitializeTrackers() {
    const userPreferences = Cookies.get(cookieConsentCookieName) || 'not_set' as CookieConsent;
    console.log('user preferences:', userPreferences);

    if (userPreferences === 'denied') {
        console.log('User does not wish to be tracked. Doing nothing');
        return;
    }
    if (userPreferences === 'allowed') {
        console.log('User has allowed tracking. Initializing trackers');
        initTrackers();
        return;
    }
    if (userPreferences === 'not_set') {
        console.log('We have not prompted user for cookies. Prompting them.');
        promptUserForCookiePopup();
        return;
    }
}

function appendModalHtmlToBody(){
    const div = document.createElement('div')
    div.innerHTML = `
<!-- Modal -->
<div class="modal fade" id="cookieConsentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Vi bruger cookies</h5>
            </div>
            <div class="modal-body">
                <p>
                    Må vi bruge cookies til at se om vi får noget ud af vores reklame-kampagner?
                </p>
                <p>Din data bliver delt som beskrevet i <a href="/privacy-policy.html" target="_blank">privatlivspolitikken.</a>
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" id="cookieConsentModalDenyCookies" data-dismiss="modal">Nej tak</button>
                <button type="button" class="btn btn-primary" id="cookieConsentModalAcceptCookies" data-dismiss="modal">Ja tak</button>
            </div>
        </div>
    </div>
</div>
    `;
    document.body.appendChild(div);
}

function promptUserForCookiePopup() {
    // append html
    appendModalHtmlToBody();

    const options: ModalOption = {
        backdrop: 'static',
        keyboard: false,
        focus: true,
        show: true,
    };

    // Default to disallow cookies, remember for a year
    Cookies.set(cookieConsentCookieName, 'denied', {expires: 365})

    const modalElement = $("#cookieConsentModal");


    const acceptCookiesButton = document.querySelector<HTMLButtonElement>("#cookieConsentModalAcceptCookies")!;
    acceptCookiesButton.onclick = (event) => {
        Cookies.set(cookieConsentCookieName, 'allowed', {expires: 365})
        modalElement.modal('hide');
    }

    // display the modal
    modalElement.modal(options);

    // Reinit first method when modal has finished
    modalElement.on('hide.bs.modal', (event: any) => {
        console.log('Modal interaction finished');
        maybeInitializeTrackers();
    })
}

function initTrackers() {
    const facebookPixel = (window as any).fbq;
    console.log('Init trackers!', {facebookPixel});
    // If fbq pixel exists, grant consent here.
    if (facebookPixel){
        console.log('Facebook pixel found, granting consent');
        facebookPixel("consent", 'grant');
    } else {
        console.log('Facebook pixel not found. Either code misconfiguration or adblocker.');
    }
}


maybeInitializeTrackers();

// On sites where there is a change cookie consent button, have that pop up.
document.querySelector("#changeCookieConsent")?.addEventListener("click", () => promptUserForCookiePopup());
